import React from "react";
import tutVideo from "./bagVid.mp4";

const TutorialBit = ({ setTutorialDone, bgColorIn }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '85vh',
            backgroundColor: bgColorIn,
            padding: '20px',
            paddingTop: '0', // Added this line
            boxSizing: 'border-box'
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '15px',
                padding: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                maxHeight: '30vh',
                marginBottom: '20px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <p style={{fontSize: '20px', fontWeight: 'bold', fontFamily: 'Roboto Mono, monospace', color: "#5E4C5A", fontSize: '2.5vh',}}> Bring your bag to life!</p>
                <ul>
                <li style={{fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '2vh',}}> Face the design</li>
                <li style={{fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '2vh',}}> Make the bag flat</li>

                </ul>
            </div>
            <div style={{
                border: '20px solid white',
                borderRadius: '15px',
                overflow: 'hidden'
            }}>
                <video autoPlay loop muted controls={false} playsInline style={{maxHeight: "60vh", width: '100%'}}>
                    <source src={tutVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <button onClick={() => setTutorialDone(true)} style={{
                padding: '10px 20px',
                fontSize: '2.5vh',
                backgroundColor: '#55917F',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                marginTop: '20px'
            }}> Got It! </button>
        </div>
    );
}
export default TutorialBit;