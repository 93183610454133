import React, { useEffect, useState } from "react";
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D,
} from "ck-postar";

const CameraComponent = ({ lensID, cameraStyle }) => {
  const [borderStyle, setBorderStyle] = useState("0px solid #fff");
  const [isLoaded, setIsLoaded] = useState(false);
  const [getSession, setSession] = useState(null);
  const [myLenses, setLenses] = useState(null);
  useEffect(() => {
    let session;
    let myLens;

    const initializeCamera = async () => {
      if (getSession != null) {
        //update the lens
        session = getSession;
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: cameraStyle, width: 720, height: 1280 },
        });
        await session.applyLens(myLenses[0]);
        // console.log("Lens changed to: " + currentLens);
        // console.log("view changed to: " + cameraStyle);
        let source;
        if (cameraStyle === "environment") {
          source = createMediaStreamSource(stream);
        } else {
          source = createMediaStreamSource(stream, {
            transform: Transform2D.MirrorX,
          });
        }

        // Set the source to the session
        //await source.setRenderSize(500, 1000);
        await session.setSource(source);
        await source.setRenderSize(720, 1280);
        return;
      } else {
        setSession(session);
        //setLenses(lenses);
      }

      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzExMDMzNzA5LCJzdWIiOiIxNGU0NzQ4Ny0xNDVlLTQwYTctODA2ZS0xMzllYzZiMDg2YjR-UFJPRFVDVElPTn4xYzczMDliOC05M2Q5LTQ3ZDgtYTEyYy1mMmNlZDk2YTIwYmEifQ.W_k3TEHGE8Ry3X1G7OJku-7qWGrWhHjJNg74PWCPSOY";
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("my-canvas");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        setSession(session);
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: cameraStyle },
        });
        let source;
        if (cameraStyle === "environment") {
          source = createMediaStreamSource(stream);
        } else {
          source = createMediaStreamSource(stream, {
            transform: Transform2D.MirrorX,
          });
        }
        await session.setSource(source);
        await source.setRenderSize(720, 1280);
        const lensToLoad = "6f986b01-df25-49a2-b024-be0a0137d842";
        // console.log(lensToLoad);
        const { lenses } = await cameraKit.lensRepository.loadLensGroups([
          lensToLoad,
        ]);
        setLenses(lenses);
        // console.log(lensID)
        // console.log(lenses);
        var bagLensInex = lenses.findIndex((lens) => lens.id === lensID);
        // console.log(bagLensInex);
        await session.applyLens(lenses[bagLensInex]);
        canvas.focus();
        await session.play();
        setIsLoaded(true);
        console.log("Lens rendering has started!");
      } catch (error) {
        console.error("Error initializing camera:", error);
      }
    };

    initializeCamera();

    // Cleanup function
    return () => {
      // Perform cleanup (e.g., stop the camera session) if needed
    };
  }, [lensID, cameraStyle]); // Empty dependency array ensures that the effect runs only once on mount

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        <canvas
          id="my-canvas"
          style={{ width: "auto", height: "80vh" }}
        ></canvas>
      }
    </div>
  );
};

export default CameraComponent;
