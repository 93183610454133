import React, { useEffect } from 'react';
import CameraComponent from '../cameraModules/CameraKitModule';
import '../pagesCSS/ExamplePage.css';
import brandImage from '../brandImages/eb.jpeg';
import attributionlogo from '../images/attribution.png';

function ExamplePage() {
    useEffect(() => {
        // Set the body color when the component mounts
        document.body.style.backgroundColor = '#DEAB51'; // Example color
        document.title = 'AR-ify | Example';
    
        // Cleanup function to reset the body color when the component unmounts
        return () => {
          document.body.style.backgroundColor = ''; // Reset to default or previous color
        };
      }, []);
    return (
        <div className='base'>
            <img src={brandImage} className='topImage' alt="brandImage" />
            <div className='ck'>
                <CameraComponent lensIndex={'Example Bakery'} cameraStyle={'environment'} borderColorIn={'#438FE0'}/>
            </div>
            <div style={{ height: '4vh', width: 'auto', overflow: 'hidden', paddingTop: '1vh'}} className='attribution'>
                <img 
                    src={attributionlogo} 
                    alt="Attribution"
                    style={{ height: '100%'}} 
                />
            </div>
        </div>
    );
}

export default ExamplePage;