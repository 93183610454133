import React, { useEffect, useState } from 'react';
import CameraComponent from '../cameraModules/postarCKModule';
import '../pagesCSS/ExamplePage.css';
import attributionlogo from '../images/attribution.png';
import TutorialBit from '../helpers/TutorialBitPostar';

function cardD() {
    
    const [tutorialDone, setIsTutorialDone] = useState(false);
    useEffect(() => {
        // Set the body color when the component mounts
        document.body.style.backgroundColor = '#FFE2D1'; // Example color
        document.title = 'Yutori';
        document.body.style.overflow = 'hidden'; // Prevent scrolling
    
        // Cleanup function to reset the body color when the component unmounts
        return () => {
          document.body.style.backgroundColor = ''; // Reset to default or previous color
        };
      }, []);
    return (
        tutorialDone ? (
<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div className='base' style={{ height: "80vh", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {/* <img src={brandImage} className='topImage' alt="brandImage" /> */}
        <div className='ck' style={{ border: '10px solid white', borderRadius: '10px', maxHeight: '90vh', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CameraComponent lensIndex={'yutori'} cameraStyle={'environment'} borderColorIn={'#438FE0'} style={{ maxHeight: '70vh', transform: 'scale(1)' }} />
        </div>
        <div style={{ height: '4vh', width: 'auto', overflow: 'hidden', paddingTop: '1vh' }} className='attribution'>
            <img
                src={attributionlogo}
                alt="Attribution"
                style={{ height: '100%' }}
            />
        </div>
    </div>
</div>
        ) :
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <TutorialBit setTutorialDone={setIsTutorialDone} />
            </div>
    );
}

export default cardD;