import React, { useEffect } from 'react';
import CameraComponent from '../cameraModules/CameraKitModule';
import brandImage from '../brandImages/dfdsquare.png';
import attributionLight from '../images/attributionLight.png';

function DartFrogDesigns() {
    useEffect(() => {
        // Set the body color when the component mounts
        document.body.style.backgroundColor = '#dbd9d8';
        document.title = 'AR-ify | Example';
    
        // Cleanup function to reset the body color when the component unmounts
        return () => {
          document.body.style.backgroundColor = ''; // Reset to default or previous color
        };
      }, []);
    return (
        <div className='base'>
            <img src={brandImage} className='topImage' alt="brandImage" />
            <div className='ck'>
                <CameraComponent lensIndex={'DartFrog Designs'} cameraStyle={'environment'} borderColorIn={'#5f6f41'}/>
            </div>
            <div style={{ height: '4vh', width: 'auto', overflow: 'hidden', paddingTop: '1vh'}} className='attribution'>
                <img 
                    src={attributionLight} 
                    alt="Attribution"
                    style={{ height: '100%'}} 
                />
            </div>
        </div>
    );
}

export default DartFrogDesigns;