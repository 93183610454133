import React, { useEffect } from 'react';
import eyeLogo from '../images/eye.png';

const HomePage = () => {
    const styles = {
        body: {
            paddingTop: '50px', // to prevent the content from being hidden behind the navbar
            margin: 0,
            padding: 0,
            fontFamily: 'Arial, sans-serif',
            //backgroundColor: '#FEF5EF',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            OBackgroundSize: 'cover',
            backgroundSize: 'cover',
        },
        container: {
            paddingTop: '50px', // Add padding to the top
            paddingBottom: '50px', // Add padding to the bottom
            backgroundColor: '#FFE2D1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '90vh',
            color: 'white',
            textAlign: 'center',
            padding: '40px 20px 20px 20px',
            fontFamily: 'Roboto Mono, monospace',
        },
        header: {
            fontSize: '2.5em',
            marginBottom: '10px',
            color: "#5E4C5A",
            fontWeight: 'bold',
        },
        subheader: {
            fontSize: '1.5em',
            marginBottom: '30px',
            color: '#6BAB90'
        },
        mainContent: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // semi-transparent white
            borderRadius: '3%', // makes it round
            display: 'flex',
            maxWidth: '80vw',
            maxHeight: '80vh',
            height: 'auto',
            
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px',
    
            marginBottom: '5vh',

            color: 'black', // text color
        },
        wesContent: {
            //backgroundColor: 'rgba(255, 255, 255, 0.8)', // semi-transparent white
            borderRadius: '10%', // makes it round
            display: 'flex',
            maxWidth: '80vw',
            maxHeight: '80vh',
            height: 'auto',
            
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
    
            marginBottom: '5vh',

            color: 'black', // text color
        },
        button: {
            display: 'inline-block',
            backgroundColor: '#55917F',
            color: 'white',
            padding: '10px 20px',
            margin: '10px 2px',
            border: 'none',
            borderRadius: '5px',
            textDecoration: 'none',
            fontSize: '1em',
            transition: 'background-color 0.3s ease',
        },
        mainContentBit: {
            color: '#55917F',
            fontSize: '1.5em',
            fontWeight: 'bold',
        },
        buttonHover: {
            backgroundColor: '#ff4500',
        },
        wesText: {
            color: '#55917F',
            fontWeight: 700,
            paddingBottom: '50px',
        },
    };
    const handleButtonClick = () => {
        window.open('https://weirdeyestudios.com/Contact', '_blank');
    };
    useEffect(() => {
        document.body.style.backgroundColor = '#FFE2D1';
        document.title = 'AR-ify | Home';
        return () => {
          document.body.style.backgroundColor = ''; // Reset to default or previous color
        };
      }, []);

    return (
        <div style={styles.container}>
            <div style={styles.header}>AR-ify</div>
            <div style={styles.subheader}>Your brand elevated.</div>
            <div style={styles.mainContent}><p style={styles.mainContentBit}>AR for your brand</p>We believe in the power of AR to bridge the gap between the physical and digital worlds, creating a seamless and interactive journey for your customers. Our platform empowers brands to showcase their products in a dynamic, immersive environment, accessible with the simple scan of a QR code. Whether it's to highlight the unique features of a product, tell a brand's story, or simply to entertain, our AR experiences are designed to captivate and engage. </div>
            <a href="example" style={styles.button}>Learn More</a>
            <br></br>            <br></br>
            <div style={styles.mainContent}><p style={styles.mainContentBit}>Our mission</p>Our mission is to make augmented reality accessible to brands of all sizes, enabling them to elevate their marketing strategies and connect with their audience on a deeper level. With AR-ify, the possibilities are limitless. From interactive product demos to virtual try-ons, and engaging games, we provide the tools you need to create experiences that resonate with your customers and set your brand apart.</div>
            {/* <a href="example" style={styles.button}>Learn More</a> */}
            <br></br>            <br></br>
            <button  onClick={handleButtonClick} style={styles.button}>Contact Us</button>
            <div style={styles.wesContent}>
            <img src={eyeLogo} style={{maxHeight: '10vh'}} alt="brandImage" />
            <div style={styles.wesText}> A Weird Eye Studios Product </div>
            </div>
        </div>
    );
};

export default HomePage;