import React, { useEffect, useState } from "react";
import CameraComponent from "../cameraModules/bagCKModule";
import "../pagesCSS/ExamplePage.css";
import attributionlogo from "../images/attribution.png";
import TutorialBit from "../helpers/TutorialBitBag";
import { useParams } from 'react-router-dom';
import Bagdata from "./Bagdata";

function BagRegular() {

    var myBag;
  const [tutorialDone, setIsTutorialDone] = useState(false);


  const { subpath } = useParams();
  console.log(subpath);

  myBag = Bagdata(subpath);
  console.log(myBag);



  useEffect(() => {
    // Set the body color when the component mounts
    document.body.style.backgroundColor = myBag.bgColor; // Example color
    document.title = myBag.headerText;
    document.body.style.overflow = "hidden"; // Prevent scrolling

    // Cleanup function to reset the body color when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Reset to default or previous color
    };
  }, []);
  return tutorialDone ? (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="base"
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="ck"
          style={{
            border: "10px solid white",
            borderRadius: "10px",
            maxHeight: "90vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CameraComponent
            lensID={myBag.lensID}
            cameraStyle={"environment"}

          />
        </div>
        <div
          style={{
            height: "4vh",
            width: "auto",
            overflow: "hidden",
            paddingTop: "1vh",
          }}
          className="attribution"
        >
          <img
            src={attributionlogo}
            alt="Attribution"
            style={{ height: "100%" }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <TutorialBit setTutorialDone={setIsTutorialDone} />
    </div>
  );
}

export default BagRegular;
