import React, { useEffect } from 'react';

const RedirectPage = () => {
    useEffect(() => {
        window.location.href = 'https://weirdeyestudios.com/Privacy';
    }, []);

    return null;
};

export default RedirectPage;