import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ExamplePage from './pages/ExamplePage';
import RedirectPage from './pages/PrivacyPage';
import DartFrogDesigns from './pages/DFD';
import CardDonut from './pages/CardDonut';
import CardStar from './pages/CardStar';
import CardSpace from './pages/CardSpace';
import CardHeart from './pages/CardHeart';
import CardParty from './pages/CardParty';

import Wabisabi from './postars/Wabisabi';
import Ikigai from './postars/Ikigai';
import Ibasho from './postars/Ibasho';
import Yutori from './postars/Yutori';

import Space from './space/Space';
import SpaceSmall from './space/SpaceSmall';

import BagRegular from './Bags/BagRegular';

//expo build:web
const App = () => {
    return (
        <Router>
            <Routes>
                {/* Core bits */}
                <Route path="/" element={<HomePage />} />
                <Route path="/example" element={<ExamplePage />} />
                <Route path="/privacy" element={<RedirectPage />} />
                <Route path="*" element={<HomePage />} />
                <Route path="/dartfrogdesigns" element={<DartFrogDesigns />} />
                {/* end of core bits */}

                {/* start of cards S1 */}
                <Route path="/cardDonut" element={<CardDonut />} />
                <Route path="/cardStar" element={<CardStar />} />
                <Route path="/cardSpace" element={<CardSpace />} />
                <Route path="/cardHeart" element={<CardHeart />} />
                <Route path="/cardParty" element={<CardParty />} />
                {/* end of cards S1 */}

                {/* start of postars S1 */}
                <Route path="/wabisabi" element={<Wabisabi />} />
                <Route path="/ikigai" element={<Ikigai />} />
                <Route path="/ibasho" element={<Ibasho />} />
                <Route path="/yutori" element={<Yutori />} />
                {/* end of postars S1 */}

                {/* start of spaces S1 */}
                <Route path="/space" element={<Space />} />
                <Route path="/space/:subpath" element={<Space />} />

                <Route path="/spaceSmall" element={<SpaceSmall />} />
                <Route path="/spaceSmall/:subpath" element={<SpaceSmall />} />
                <Route path="/bag/:subpath" element={<BagRegular />} />

                {/* end of spaces S1 */}
            </Routes>
        </Router>
    );
};

export default App;